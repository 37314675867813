import React, { useEffect } from "react";
import loginImg from "../../monkey.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

export function Login({ merchantId, employeeId, clientId, code, ...props }) { 
  
  const {t, i18n} = useTranslation('common');

  useEffect(() => {
    onSignIn()
  });

  function onSignIn() {
    fetch(process.env.REACT_APP_API_URL + '/clover', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ merchantId, employeeId, clientId, code })
    })
      .then((res) => {
        if (res.status === 200) {
          res.text().then((token) => {
            // save token in local storage and redirect.
            window.localStorage.setItem("token", token);
            window.location.href = process.env.REACT_APP_TAGET_SERVICE_FRONTEND;
          });
        } else {
          res.json().then((json) => {
            toast.error(json.error);
          });
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <ToastContainer />
      <div className="base-container" >
        <div className="content">
          <div className="image">
            <img src={loginImg} alt="ms logo" />
          </div>
          <div className="header">{t('login.title')}</div>
        </div>
      </div>
    </>
  );
}