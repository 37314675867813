import React from "react";
import "./App.scss";
import { Login, Register, PasswordRequest, PasswordReset } from "./componenets/login/index";

class App extends React.Component {
  constructor(props) {
    // Store i18nextLng in localStorage
    localStorage.setItem('i18nextLng', navigator.language)

    super(props);
    this.state = {
      merchantId: undefined,
      employeeId: undefined,
      clientId: undefined,
      code: undefined
    };

    if (window.location.pathname === '/') {
      const merchantId = new URLSearchParams(window.location.search).get("merchant_id");
      const employeeId = new URLSearchParams(window.location.search).get("employee_id");
      const clientId = new URLSearchParams(window.location.search).get("client_id");
      const code = new URLSearchParams(window.location.search).get("code");
      
      this.state.merchantId = merchantId;
      this.state.employeeId = employeeId;
      this.state.clientId = clientId;
      this.state.code = code;
    }

    if (window.location.pathname !== '/') {
      const merchantId = new URLSearchParams(window.location.search).get("merchant_id");
      const employeeId = new URLSearchParams(window.location.search).get("employee_id");
      const clientId = new URLSearchParams(window.location.search).get("client_id");
      const code = new URLSearchParams(window.location.search).get("code");
      
      const token = window.localStorage.getItem("token");
      if (token && (!merchantId && !employeeId && !clientId && !code)) {
        window.location.href = process.env.REACT_APP_TAGET_SERVICE_FRONTEND;
      }
    }
  }

  render() {
    return (
      <div className="App">
        <div className="login">
          <div className="container" ref={(ref) => (this.container = ref)}>
            <Login 
              merchantId={this.state.merchantId}
              employeeId={this.state.employeeId}
              clientId={this.state.clientId}
              code={this.state.code} 
            />
          </div>
        </div>
      </div>
    );
  }
}

export default App;
